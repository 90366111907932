import Layout from "../components/layout"
import SEO from "../components/seo"
import * as React from "react"
import { graphql, Link } from "gatsby"
import BookingLink from "../components/booking/booking-link"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import Map from "../components/geo-related/map"
import { postAccoView } from "../components/analytics"
import { facilities } from "../consts/facilities"
import Facility from "../components/facility"
import { decodePhotoUrl } from "../utils/photo_url"
import Books, { IBookFragment } from "../components/books"
import { IBookingHotel } from "../shared-interfaces/booking-hotel"
import { IHop } from "../shared-interfaces/hop"

/*
hotel.photo_url

->  https://r-cf.bstatic.com/images/hotel/max500/232/232808388.jpg
 https://r-cf.bstatic.com/images/hotel/max1024x768/232/232808388.jpg
 https://r-cf.bstatic.com/images/hotel/max1280x900/232/232808388.jpg

 */
const sectionCss = css`
  max-width: 790px;
  padding: 0 ${rhythm(0.75)};
  margin: auto;
`

const subSectionCss = css`
  margin: ${rhythm(0.5)} 0;
`

interface Props {
  data: {
    bookingHotels: IBookingHotel;
    contentfulTrip: {
      fields: {
        yovelinoSlug: string;
      };
      title: string;
      books: IBookFragment[]
    }
  },
  pageContext: {
    yovelinoSlug: string;
    id: string; // acco id
    track: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
    hopsWithAccoIds: IHop[];
    kilometerMarkerCoords: number[][];
  }
}


export default ({ data, pageContext }: Props) => {
  const tour = data.contentfulTrip
  const hotel = data.bookingHotels
  const track = pageContext.track
  const hopsWithAccoIds = pageContext.hopsWithAccoIds
  const kilometerMarkerCoords = pageContext.kilometerMarkerCoords

  postAccoView(tour.fields.yovelinoSlug, hotel)

  return (
    <Layout>
      <SEO title={tour.title + " | " + hotel.name}/>
      <div css={sectionCss}>
        <h1>{hotel.name}</h1>
        <div>
          <img src={decodePhotoUrl(hotel.p_url)} alt={hotel.name}/>
        </div>

        <div css={subSectionCss}>
          <div>Bewertung: {hotel.rating}</div>
          <div>Preis ab: {hotel.minrate} €</div>
        </div>
        <div css={css`
          margin-bottom: ${rhythm(0.75)}
        `}>
          <BookingLink acco={hotel} tourSlug={tour.fields.yovelinoSlug} withIcon={true}/>
        </div>

        <div css={subSectionCss}>
          {hotel.desc_de}
          {hotel.address && (<div>{hotel.address}</div>)}
        </div>


        <div css={subSectionCss}>
          {(hotel.checkinFrom || hotel.checkinUntil) && (
            <div>
              Checkin:
              {hotel.checkinFrom && (<span> ab {hotel.checkinFrom} Uhr</span>)}
              {hotel.checkinUntil && (<span> bis {hotel.checkinUntil} Uhr</span>)}
            </div>
          )}
          {(hotel.checkoutFrom || hotel.checkoutUntil) && (
            <div>
              Checkout:
              {hotel.checkoutFrom && (<span> ab {hotel.checkoutFrom} Uhr</span>)}
              {hotel.checkoutUntil && (<span> bis {hotel.checkoutUntil} Uhr</span>)}
            </div>
          )}
        </div>

        {(!!hotel.f && (
          <div css={subSectionCss}>
            <div>Das bietet die Unterkunft:</div>
            <div css={css`
                display: flex;
                flex-wrap: wrap;
            `}>
              {facilities.map((f: any) => (<Facility key={f.label} facilities={hotel.f} facility={f.facility} label={f.label}
                                                     icon={f.icon}/>))}
            </div>
          </div>
        ))}

        <div css={subSectionCss}>Lage am <Link className="link__internal"
                                               to={tour.fields.yovelinoSlug}>{tour.title}</Link>
        </div>
        <div css={subSectionCss}>
          {
            (typeof window !== "undefined" && track)
              ? (<Map track={track} accos={[hotel]} hops={hopsWithAccoIds}
                      tourSlug={tour.fields.yovelinoSlug}
                      kilometerMarkerCoords={kilometerMarkerCoords}/>)
              : null
          }
        </div>
        {(tour.books || []).length > 0 && (<Books books={tour.books}/>)}
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($yovelinoSlug: String!,  $id: String!) {
        bookingHotels(_id: {eq: $id}) {
            name
            hotel_url
            desc_de
            p_url
            rating
            minrate
            checkinFrom
            checkinUntil
            checkoutFrom
            checkoutUntil
            f:facilities
            address
            latLng
        }
        contentfulTrip(fields: { yovelinoSlug: { eq: $yovelinoSlug } }) {
            fields {
                yovelinoSlug
            }
            title
            books:amazonBooks {
                ...BookFragment
            }
        }
    }
`
