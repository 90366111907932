import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import * as React from "react"


type Props = {
  facilities: number;
  facility: number;
  label: string;
  icon: string;
}

const Facility = ({ facilities, facility, label, icon }: Props) => {
  const facilityMatch = (facilities & facility) === facility
  if (!facilityMatch) {
    return <></>
  }
  return (
    <div css={css`
                  margin-right: ${rhythm(0.75)};
                `}><i className={`icon ${icon}`}/>{label}</div>)
}

export default React.memo(Facility)
